import { title } from "@/@core/utils/filter";

export default [ 
  {
    title: 'Próximas americanas',
    route: 'UpcomingTournaments',
    icon: 'CalendarIcon',
  },
  {
    title: 'Próximas canarias',
    route: 'UpcomingCanarias',
    icon: 'CalendarIcon',
  },
  {
    title: 'Tus torneos',
    route: 'PlayerTournaments',
    icon: 'FlagIcon',
  },
  {
    title: 'Rankings',
    // route: 'UpcomingTournaments',
    icon: 'ListIcon',
    children: [
      {
        title: 'Ranking club',
        children: [
          {
          title: 'Ranking cuantitativo', 
          route: {name: 'QuantitativeClubRanking'}   
          },
          {
          title: 'Ranking cualitativo', 
          route: {name: 'QualitativeClubRanking'}   
          }
        ]       
      },
      {
        title: 'Tus Rankings',
        route: {name: 'PlayerRankings'}   
      }
    ]
  },
 
  
  
]
